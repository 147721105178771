import React, { useState } from 'react';

import Layout from '@components/Layout/Layout';
import Seo from '@components/Seo/Seo';
import Tabs from '@components/Tabs/Tabs';
import AdminAvailabilities from '@components/AdminAvailabilities/AdminAvailabilities';
import AdminTransactions from '@components/AdminTransactions/AdminTransactions';

const AdminPage = ({ location }) => {
  const [tab, handleTab] = useState('disponibilita');
  return (
    <Layout location={location}>
      <Seo title="Admin - Tiziano Bieller Sport" lang="it" />
      <Tabs
        tabs={['disponibilita', 'prenotazioni']}
        onClick={handleTab}
        current={tab}
      />
      {tab === 'disponibilita' && <AdminAvailabilities />}
      {tab === 'prenotazioni' && <AdminTransactions />}
    </Layout>
  );
};

export default AdminPage;
