// extracted by mini-css-extract-plugin
export var availabilitiesHeader = "AdminTransactions-module--availabilitiesHeader--364ba";
export var availabilitiesTitle = "AdminTransactions-module--availabilitiesTitle--29fec";
export var availability = "AdminTransactions-module--availability--b2821";
export var availabilityDate = "AdminTransactions-module--availabilityDate--b3e22";
export var container = "AdminTransactions-module--container--beb38";
export var form = "AdminTransactions-module--form--b0aca";
export var input = "AdminTransactions-module--input--c70e5";
export var loader = "AdminTransactions-module--loader--20745";
export var loginContainer = "AdminTransactions-module--loginContainer--3f68f";
export var moreButton = "AdminTransactions-module--moreButton--caf21";
export var toastTitle = "AdminTransactions-module--toastTitle--64f3a";
export var transactionsBlock = "AdminTransactions-module--transactionsBlock--f820f";
export var transactionsCol = "AdminTransactions-module--transactionsCol--5beea";
export var transactionsDate = "AdminTransactions-module--transactionsDate--970b7";
export var transactionsHeader = "AdminTransactions-module--transactionsHeader--5df43";
export var transactionsList = "AdminTransactions-module--transactionsList--98604";
export var transactionsRow = "AdminTransactions-module--transactionsRow--e1dea";
export var transactionsTitle = "AdminTransactions-module--transactionsTitle--4a6c6";
export var type = "AdminTransactions-module--type--34516";
export var typeInput = "AdminTransactions-module--typeInput--32d97";
export var typeLable = "AdminTransactions-module--typeLable--d1c97";