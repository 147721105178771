import React, { useState, useEffect } from 'react';
import ClipLoader from 'react-spinners/ClipLoader';

import { getAvailabilities, setAvailabilities } from '@api';

import {
  getLocalStorageLogin,
  setLocalStorageLogin,
  insertSlash,
} from '@utils/';

import Button from '@components/Button/Button';
import Toast from '@components/Toast/Toast';

import * as styles from './AdminAvailabilities.module.css';

const AdminAvailabilities = () => {
  const [loading, handleLoading] = useState(false);
  const [loadingButton, handleLoadingButton] = useState(false);
  const [username, handleUsername] = useState(null);
  const [password, handlePassword] = useState(null);
  const [limit, handleLimit] = useState(5);

  const [availabilities, handleAvailabilities] = useState(null);
  const [changed, handleChanged] = useState([]);
  const [availabilitiesError, handleAvailabilitiesError] = useState(false);
  const [showDisabled, handleShowDisabled] = useState(false);

  const login = getLocalStorageLogin();
  const [loggedIn, handleLoggedIn] = useState(
    login === process.env.GATSBY_APP_LOGIN || false
  );

  const onLogin = () => {
    const isLoginOk =
      `${username}_${password}` === process.env.GATSBY_APP_LOGIN;
    if (isLoginOk) {
      setLocalStorageLogin({ username, password });
      handleLoggedIn(true);
    }
  };

  const onAvalabilityChange = (id, type, value) => {
    const clonedAvailabilities = JSON.parse(JSON.stringify(availabilities));
    let changedAvailabilities = JSON.parse(JSON.stringify(changed));
    const newAvailabilities = clonedAvailabilities.map(av => {
      if (av.id === id) {
        av.data[type] = parseInt(value, 10);

        const changedIdex = changedAvailabilities.findIndex(el => el.id === id);
        if (changedAvailabilities.length === 0 || changedIdex === -1) {
          changedAvailabilities.push({
            id: av.id,
            date: av.date,
            data: { [type]: av.data[type] },
          });
        } else {
          changedAvailabilities[changedIdex] = {
            id: av.id,
            date: av.date,
            data: {
              ...changedAvailabilities[changedIdex].data,
              [type]: av.data[type],
            },
          };
        }
      }
      return av;
    });
    handleAvailabilities(newAvailabilities);
    handleChanged(changedAvailabilities);
  };

  const onSave = async () => {
    handleLoadingButton(true);

    const updates = [];
    changed.forEach(c => {
      const { data } = c;
      Object.keys(data).forEach(key => {
        updates.push({ id: c.id, type: key, quantity: data[key] });
      });
    });

    await setAvailabilities(updates);
    handleChanged([]);
    handleLoadingButton(false);
    get(true);
  };

  const renderLoginForm = () => {
    return (
      <div className={styles.form}>
        <input
          className={styles.input}
          type="text"
          placeholder="Username"
          //   value={username || ''}
          onChange={e => {
            handleUsername(e.target.value);
          }}
          autoComplete="off"
        />
        <input
          className={styles.input}
          type="password"
          placeholder="Password"
          //   value={password || ''}
          onChange={e => {
            handlePassword(e.target.value);
          }}
          autoComplete="off"
        />
        <Button onClick={onLogin} full>
          LOGIN
        </Button>
      </div>
    );
  };

  const renderAvailabilities = () => {
    return (
      <div className={styles.availabilitiesList}>
        <div className={styles.availabilitiesHeader}>
          <h2 className={styles.availabilitiesTitle}>DISPONIBILITA</h2>
          <div>
            <Button
              type="secondary"
              onClick={() => {
                handleShowDisabled(!showDisabled);
              }}
            >
              {showDisabled ? 'HIDE DISABLED' : 'SHOW DISABLED'}
            </Button>
            <Button
              style={{ marginLeft: '8px' }}
              onClick={() => {
                onSave();
              }}
              loading={loadingButton}
              disabled={changed.length === 0}
            >
              SAVE
            </Button>
          </div>
        </div>
        {availabilities &&
          availabilities.slice(0, limit).map(av => {
            const kidFull = typeof av.data['kid-full'] !== 'undefined';
            const kidFullShow = kidFull || (!kidFull && showDisabled);
            const kidHalfmorning =
              typeof av.data['kid-halfmorning'] !== 'undefined';
            const kidHalfmorningShow =
              kidHalfmorning || (!kidHalfmorning && showDisabled);
            const kidHalfafternoon =
              typeof av.data['kid-halfmorning'] !== 'undefined';
            const kidHalfafternoonShow =
              kidHalfafternoon || (!kidHalfafternoon && showDisabled);

            const xlFull = typeof av.data['xl-full'] !== 'undefined';
            const xlFullShow = xlFull || (!xlFull && showDisabled);
            const xlHalfmorning =
              typeof av.data['xl-halfmorning'] !== 'undefined';
            const xlHalfmorningShow =
              xlHalfmorning || (!xlHalfmorning && showDisabled);
            const xlHalfafternoon =
              typeof av.data['xl-halfmorning'] !== 'undefined';
            const xlHalfafternoonShow =
              xlHalfafternoon || (!xlHalfafternoon && showDisabled);

            const lFull = typeof av.data['l-full'] !== 'undefined';
            const lFullShow = lFull || (!lFull && showDisabled);
            const lHalfmorning =
              typeof av.data['l-halfmorning'] !== 'undefined';
            const lHalfmorningShow =
              lHalfmorning || (!lHalfmorning && showDisabled);
            const lHalfafternoon =
              typeof av.data['l-halfmorning'] !== 'undefined';
            const lHalfafternoonShow =
              lHalfafternoon || (!lHalfafternoon && showDisabled);

            const mFull = typeof av.data['m-full'] !== 'undefined';
            const mFullShow = mFull || (!mFull && showDisabled);
            const mHalfmorning =
              typeof av.data['m-halfmorning'] !== 'undefined';
            const mHalfmorningShow =
              mHalfmorning || (!mHalfmorning && showDisabled);
            const mHalfafternoon =
              typeof av.data['m-halfmorning'] !== 'undefined';
            const mHalfafternoonShow =
              mHalfafternoon || (!mHalfafternoon && showDisabled);

            const sFull = typeof av.data['s-full'] !== 'undefined';
            const sFullShow = sFull || (!sFull && showDisabled);
            const sHalfmorning =
              typeof av.data['s-halfmorning'] !== 'undefined';
            const sHalfmorningShow =
              sHalfmorning || (!sHalfmorning && showDisabled);
            const sHalfafternoon =
              typeof av.data['s-halfmorning'] !== 'undefined';
            const sHalfafternoonShow =
              sHalfafternoon || (!sHalfafternoon && showDisabled);

            return (
              <div className={styles.availability} key={av.id}>
                <h3 className={styles.availabilityDate}>
                  {insertSlash(av.id)}
                </h3>

                <div>
                  {kidFullShow && (
                    <div className={styles.type}>
                      <span className={styles.typeLable}>
                        BAMBINO GIORNATA INTERA
                      </span>
                      <input
                        className={styles.typeInput}
                        type="number"
                        value={av.data['kid-full'] || 0}
                        disabled={!kidFull}
                        onChange={e => {
                          onAvalabilityChange(
                            av.id,
                            'kid-full',
                            e.target.value
                          );
                        }}
                      />
                    </div>
                  )}

                  {kidHalfmorningShow && (
                    <div className={styles.type}>
                      <span className={styles.typeLable}>
                        BAMBINO MEZZA GIORNATA (9-13)
                      </span>
                      <input
                        className={styles.typeInput}
                        type="number"
                        value={av.data['kid-halfmorning'] || 0}
                        disabled={!kidHalfmorning}
                        onChange={e => {
                          onAvalabilityChange(
                            av.id,
                            'kid-halfmorning',
                            e.target.value
                          );
                        }}
                      />
                    </div>
                  )}

                  {kidHalfafternoonShow && (
                    <div className={styles.type}>
                      <span className={styles.typeLable}>
                        BAMBINO MEZZA GIORNATA (14-18)
                      </span>
                      <input
                        className={styles.typeInput}
                        type="number"
                        value={av.data['kid-halfafternoon'] || 0}
                        disabled={!kidHalfafternoon}
                        onChange={e => {
                          onAvalabilityChange(
                            av.id,
                            'kid-halfafternoon',
                            e.target.value
                          );
                        }}
                      />
                    </div>
                  )}

                  {/** TAGLIA XL */}
                  {xlFullShow && (
                    <div className={styles.type}>
                      <span className={styles.typeLable}>
                        XL GIORNATA INTERA
                      </span>
                      <input
                        className={styles.typeInput}
                        type="number"
                        value={av.data['xl-full'] || 0}
                        disabled={!xlFull}
                        onChange={e => {
                          onAvalabilityChange(av.id, 'xl-full', e.target.value);
                        }}
                      />
                    </div>
                  )}
                  {xlHalfmorningShow && (
                    <div className={styles.type}>
                      <span className={styles.typeLable}>
                        XL MEZZA GIORNATA (9-13)
                      </span>
                      <input
                        className={styles.typeInput}
                        type="number"
                        value={av.data['xl-halfmorning'] || 0}
                        disabled={!xlHalfmorning}
                        onChange={e => {
                          onAvalabilityChange(
                            av.id,
                            'xl-halfmorning',
                            e.target.value
                          );
                        }}
                      />
                    </div>
                  )}
                  {xlHalfafternoonShow && (
                    <div className={styles.type}>
                      <span className={styles.typeLable}>
                        XL MEZZA GIORNATA (14-18)
                      </span>
                      <input
                        className={styles.typeInput}
                        type="number"
                        value={av.data['xl-halfafternoon'] || 0}
                        disabled={!xlHalfafternoon}
                        onChange={e => {
                          onAvalabilityChange(
                            av.id,
                            'xl-halfafternoon',
                            e.target.value
                          );
                        }}
                      />
                    </div>
                  )}
                  {/** TAGLIA L */}
                  {lFullShow && (
                    <div className={styles.type}>
                      <span className={styles.typeLable}>
                        L GIORNATA INTERA
                      </span>
                      <input
                        className={styles.typeInput}
                        type="number"
                        value={av.data['l-full'] || 0}
                        disabled={!lFull}
                        onChange={e => {
                          onAvalabilityChange(av.id, 'l-full', e.target.value);
                        }}
                      />
                    </div>
                  )}
                  {lHalfmorningShow && (
                    <div className={styles.type}>
                      <span className={styles.typeLable}>
                        L MEZZA GIORNATA (9-13)
                      </span>
                      <input
                        className={styles.typeInput}
                        type="number"
                        value={av.data['l-halfmorning'] || 0}
                        disabled={!lHalfmorning}
                        onChange={e => {
                          onAvalabilityChange(
                            av.id,
                            'l-halfmorning',
                            e.target.value
                          );
                        }}
                      />
                    </div>
                  )}
                  {lHalfafternoonShow && (
                    <div className={styles.type}>
                      <span className={styles.typeLable}>
                        L MEZZA GIORNATA (14-18)
                      </span>
                      <input
                        className={styles.typeInput}
                        type="number"
                        value={av.data['l-halfafternoon'] || 0}
                        disabled={!lHalfafternoon}
                        onChange={e => {
                          onAvalabilityChange(
                            av.id,
                            'l-halfafternoon',
                            e.target.value
                          );
                        }}
                      />
                    </div>
                  )}

                  {/** TAGLIA M */}
                  {mFullShow && (
                    <div className={styles.type}>
                      <span className={styles.typeLable}>
                        M GIORNATA INTERA
                      </span>
                      <input
                        className={styles.typeInput}
                        type="number"
                        value={av.data['m-full'] || 0}
                        disabled={!mFull}
                        onChange={e => {
                          onAvalabilityChange(av.id, 'm-full', e.target.value);
                        }}
                      />
                    </div>
                  )}
                  {mHalfmorningShow && (
                    <div className={styles.type}>
                      <span className={styles.typeLable}>
                        M MEZZA GIORNATA (9-13)
                      </span>
                      <input
                        className={styles.typeInput}
                        type="number"
                        value={av.data['m-halfmorning'] || 0}
                        disabled={!mHalfmorning}
                        onChange={e => {
                          onAvalabilityChange(
                            av.id,
                            'm-halfmorning',
                            e.target.value
                          );
                        }}
                      />
                    </div>
                  )}
                  {mHalfafternoonShow && (
                    <div className={styles.type}>
                      <span className={styles.typeLable}>
                        M MEZZA GIORNATA (14-18)
                      </span>
                      <input
                        className={styles.typeInput}
                        type="number"
                        value={av.data['m-halfafternoon'] || 0}
                        disabled={!mHalfafternoon}
                        onChange={e => {
                          onAvalabilityChange(
                            av.id,
                            'm-halfafternoon',
                            e.target.value
                          );
                        }}
                      />
                    </div>
                  )}

                  {/** TAGLIA S */}
                  {sFullShow && (
                    <div className={styles.type}>
                      <span className={styles.typeLable}>
                        S GIORNATA INTERA
                      </span>
                      <input
                        className={styles.typeInput}
                        type="number"
                        value={av.data['s-full'] || 0}
                        disabled={!sFull}
                        onChange={e => {
                          onAvalabilityChange(av.id, 's-full', e.target.value);
                        }}
                      />
                    </div>
                  )}
                  {sHalfmorningShow && (
                    <div className={styles.type}>
                      <span className={styles.typeLable}>
                        S MEZZA GIORNATA (9-13)
                      </span>
                      <input
                        className={styles.typeInput}
                        type="number"
                        value={av.data['s-halfmorning'] || 0}
                        disabled={!sHalfmorning}
                        onChange={e => {
                          onAvalabilityChange(
                            av.id,
                            's-halfmorning',
                            e.target.value
                          );
                        }}
                      />
                    </div>
                  )}
                  {sHalfafternoonShow && (
                    <div className={styles.type}>
                      <span className={styles.typeLable}>
                        S MEZZA GIORNATA (14-18)
                      </span>
                      <input
                        className={styles.typeInput}
                        type="number"
                        value={av.data['s-halfafternoon'] || 0}
                        disabled={!sHalfafternoon}
                        onChange={e => {
                          onAvalabilityChange(
                            av.id,
                            's-halfafternoon',
                            e.target.value
                          );
                        }}
                      />
                    </div>
                  )}
                </div>

                <Button
                  style={{ marginTop: '16px' }}
                  onClick={() => {
                    onSave();
                  }}
                  loading={loadingButton}
                  disabled={changed.filter(el => el.id === av.id).length === 0}
                >
                  SAVE
                </Button>
              </div>
            );
          })}
      </div>
    );
  };

  const get = async noloading => {
    if (!noloading) handleLoading(true);
    try {
      const a = await getAvailabilities(true);

      const avail = a && a.data ? a.data.availabilities : [];
      handleAvailabilities(avail);
    } catch (error) {
      handleAvailabilitiesError(true);
    }
    if (!noloading) handleLoading(false);
  };

  useEffect(() => {
    if (loggedIn) get();
  }, [loggedIn]);

  return (
    <div className={styles.container}>
      {!loggedIn ? (
        <div className={styles.loginContainer}>{renderLoginForm()}</div>
      ) : (
        <div>
          {loading && (
            <div className={styles.loader}>
              <ClipLoader color="#093f98" loading />
            </div>
          )}

          {availabilitiesError && (
            <Toast type="error">
              <h3 className={styles.toastTitle}>
                Ops, qualcosa è andato storto durante il controllo delle
                disponibilità!
              </h3>
            </Toast>
          )}

          {!loading &&
            !availabilitiesError &&
            availabilities &&
            renderAvailabilities()}
        </div>
      )}

      {availabilities && limit < availabilities.length && (
        <div className={styles.moreButton}>
          <Button
            type="secondary"
            onClick={() => {
              handleLimit(limit + 5);
            }}
          >
            SHOW MORE
          </Button>
        </div>
      )}
    </div>
  );
};

export default AdminAvailabilities;
