import React, { useState, useEffect } from 'react';
import ClipLoader from 'react-spinners/ClipLoader';

import { getTransactions } from '@api';

import {
  getLocalStorageLogin,
  setLocalStorageLogin,
  insertSlash,
  formatTimeLabel,
  formatTime,
  formatSize,
  formatPriceNumber,
  dateToId,
} from '@utils/';

import Button from '@components/Button/Button';
import Toast from '@components/Toast/Toast';

import * as styles from './AdminTransactions.module.css';

const AdminTransactions = () => {
  const [loading, handleLoading] = useState(false);
  const [username, handleUsername] = useState(null);
  const [password, handlePassword] = useState(null);
  const [transactionsError, handleTransactionsError] = useState(false);

  const [transactions, handleTransactions] = useState(null);

  const d = new Date();
  const availableDates = [
    dateToId(d),
    dateToId(d, 1),
    dateToId(d, 2),
    dateToId(d, 3),
    dateToId(d, 4),
    dateToId(d, 5),
  ];

  const login = getLocalStorageLogin();
  const [loggedIn, handleLoggedIn] = useState(
    login === process.env.GATSBY_APP_LOGIN || false
  );

  const onLogin = () => {
    const isLoginOk =
      `${username}_${password}` === process.env.GATSBY_APP_LOGIN;
    if (isLoginOk) {
      setLocalStorageLogin({ username, password });
      handleLoggedIn(true);
    }
  };

  const renderLoginForm = () => {
    return (
      <div className={styles.form}>
        <input
          className={styles.input}
          type="text"
          placeholder="Username"
          //   value={username || ''}
          onChange={e => {
            handleUsername(e.target.value);
          }}
          autoComplete="off"
        />
        <input
          className={styles.input}
          type="password"
          placeholder="Password"
          //   value={password || ''}
          onChange={e => {
            handlePassword(e.target.value);
          }}
          autoComplete="off"
        />
        <Button onClick={onLogin} full>
          LOGIN
        </Button>
      </div>
    );
  };

  const renderTransactions = () => {
    return (
      <div className={styles.transactionsList}>
        <div className={styles.transactionsHeader}>
          <h2 className={styles.transactionsTitle}>PRENOTAZIONI</h2>
        </div>
        <div>
          {transactions
            ? Object.keys(transactions)
                .filter(k => availableDates.indexOf(k) !== -1)
                .map(key => {
                  const thisTransactions = transactions[key];
                  return (
                    <div key={key} className={styles.transactionsBlock}>
                      <h3 className={styles.transactionsDate}>
                        {insertSlash(key)}
                      </h3>
                      <div>
                        {thisTransactions.map(tt => {
                          const { customer_details, metadata, amount_total } =
                            tt;
                          const valueArray = metadata[0].split('###');
                          const type = valueArray[1];
                          const quantity = parseInt(valueArray[2], 10);
                          return (
                            <div key={tt.id} className={styles.transactionsRow}>
                              <div className={styles.transactionsCol}>
                                <strong>{customer_details.name}</strong>
                                <br />
                                <a href={`tel:${customer_details.phone}`}>
                                  {customer_details.phone}
                                </a>
                                <br />
                                <a href={`mailto:${customer_details.email}`}>
                                  {customer_details.email}
                                </a>
                              </div>
                              <div className={styles.transactionsCol}>
                                TAGLIA: {formatSize(type)}
                                <br />
                                DURATA: {formatTimeLabel(formatTime(type))}
                                <br />
                                QUANTITA: {quantity}
                                <br />
                              </div>
                              <div className={styles.transactionsCol}>
                                PREZZO: {formatPriceNumber(amount_total)} &euro;
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  );
                })
            : null}
        </div>
      </div>
    );
  };

  const get = async noloading => {
    if (!noloading) handleLoading(true);
    try {
      const t = await getTransactions();

      const transactionsList = t && t.data ? t.data.transactions : [];
      handleTransactions(transactionsList);
    } catch (error) {
      handleTransactionsError(true);
    }
    if (!noloading) handleLoading(false);
  };

  useEffect(() => {
    if (loggedIn) get();
  }, [loggedIn]);

  return (
    <div className={styles.container}>
      {!loggedIn ? (
        <div className={styles.loginContainer}>{renderLoginForm()}</div>
      ) : (
        <div>
          {loading && (
            <div className={styles.loader}>
              <ClipLoader color="#093f98" loading />
            </div>
          )}

          {transactionsError && (
            <Toast type="error">
              <h3 className={styles.toastTitle}>
                Ops, qualcosa è andato storto durante il controllo delle
                transazioni!
              </h3>
            </Toast>
          )}

          {!loading &&
            !transactionsError &&
            transactions &&
            renderTransactions()}
        </div>
      )}
    </div>
  );
};

export default AdminTransactions;
