// extracted by mini-css-extract-plugin
export var availabilitiesHeader = "AdminAvailabilities-module--availabilitiesHeader--fe6f0";
export var availabilitiesList = "AdminAvailabilities-module--availabilitiesList--7a6b2";
export var availabilitiesTitle = "AdminAvailabilities-module--availabilitiesTitle--b4a99";
export var availability = "AdminAvailabilities-module--availability--4742a";
export var availabilityDate = "AdminAvailabilities-module--availabilityDate--d2565";
export var container = "AdminAvailabilities-module--container--f0b19";
export var form = "AdminAvailabilities-module--form--f52f2";
export var input = "AdminAvailabilities-module--input--72541";
export var loader = "AdminAvailabilities-module--loader--13fa5";
export var loginContainer = "AdminAvailabilities-module--loginContainer--23cd3";
export var moreButton = "AdminAvailabilities-module--moreButton--325b0";
export var toastTitle = "AdminAvailabilities-module--toastTitle--f9077";
export var type = "AdminAvailabilities-module--type--c5109";
export var typeInput = "AdminAvailabilities-module--typeInput--46bf0";
export var typeLable = "AdminAvailabilities-module--typeLable--7ce92";