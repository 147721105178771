import React from 'react';
import PropTypes from 'prop-types';

import * as styles from './Tabs.module.css';
import Button from '@components/Button/Button';

const Tabs = ({ tabs, onClick, current }) => {
  return (
    <div className={styles.tabs}>
      {tabs.map(t => {
        return (
          <div key={t} className={styles.tab}>
            <Button
              type={current === t ? '' : 'secondary'}
              onClick={() => {
                onClick(t);
              }}
            >
              {t.toUpperCase()}
            </Button>
          </div>
        );
      })}
    </div>
  );
};

Tabs.propTypes = {
  tabs: PropTypes.array,
  onClick: PropTypes.func,
  current: PropTypes.string,
};

export default Tabs;
